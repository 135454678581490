// import $ from 'jquery';
// import { gsap } from 'gsap';

// export const isDesktop = window.innerWidth > 1025;
// export const isMobile = window.innerHeight < 1024;

export const newDate = new Date();

export const loaderAnimation = async () => {
  const { gsap } = await import('gsap');
  const tl = gsap.timeline({ repeat: -1 });
  tl.fromTo(('.loading-first'), { opacity: '0.3' }, { duration: 0.3, overwrite: 'auto', opacity: '1' })
    .fromTo(('.loading-second'), { opacity: '0.3' }, { duration: 0.3, overwrite: 'auto', opacity: '1', delay: 0.3 })
    .fromTo(('.loading-third'), { opacity: '0.3' }, { duration: 0.3, overwrite: 'auto', opacity: '1', delay: 0.3 })
    .fromTo(('.loading-circle'), { opacity: '0.3' }, { duration: 0.3, overwrite: 'auto', opacity: '1', delay: 0.3 })
    .to(('.loading-circle, .loading-first, .loading-second, .loading-third'), { duration: 0.3, overwrite: 'auto', opacity: '0.3' });
};
