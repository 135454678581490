import NextLink from 'next/link'

export function Link({ to, children, ...props }) {
  return (
    <NextLink href={getUrl(to)} {...props}>
      {children}
    </NextLink>
  )
}

export function getFullUrl(pathname, search, state) {
  let fullsearch = search;
  if (search && search[0] !== '?') {
    fullsearch = `?${search}`;
  }
  if (state) {
    if (fullsearch) {
      fullsearch = `${fullsearch}&`;
    } else {
      fullsearch = '?'
    }
    fullsearch = `${fullsearch}${new URLSearchParams(state).toString()}`;
  }
  return `${pathname}${fullsearch}`
}

export function getUrl(url) {
  if (typeof url === 'string') {
    return url
  } else {
    const { pathname, search = '', state } = url;
    return getFullUrl(pathname, search, state);
  }
}
